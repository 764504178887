import type { ErrorIdent } from '~/@types/errorIdents';
import { useFavLists } from '~/stores/useFavLists';
import { useDialogStore } from '~/stores/useDialogStore';

const favListStore = useFavLists();
const dialogStore = useDialogStore();

export function useMultiSelectDelete() {
  const errors = ref<ErrorIdent[]>([]);
  const isLoading = ref(false);

  async function onDelete() {
    isLoading.value = true;
    await deleteItems();
    isLoading.value = false;
  }

  async function deleteItems() {
    try {
      const result = await favListStore.deleteSelectedItems();

      if (result) {
        dialogStore.closeDialog();
      }
    } catch (e: any) {
      errors.value = e.data.data.errors;
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    errors,
    onDelete,
  };
}
