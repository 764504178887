<template>
  <Dialog width-class="md:max-w-[510px]">
    <div class="px-md pb-md flex flex-col max-h-[92vh]">
      <div v-if="$siteIdent.startsWith('minilu')" class="flex justify-center">
        <img src="@/assets/images/minilu/trash.svg" class="relative -top-md" />
      </div>

      <div v-else class="flex justify-center mb-sm mt-md">
        <HeadlineDynamic
          headline="favLists.multiSelect.deleteItems"
          level="h4"
        />
      </div>
      <div class="overflow-auto h-fit">
        <div
          v-if="errors.length && errors.includes(ErrorIdent.UNKNOWN_ERROR)"
          class="mb-md"
        >
          <UnknownError />
        </div>
        <div class="text-center mb-md">
          {{ t('favLists.multiSelect.delete.dialog.text') }}
        </div>
        <ButtonBasic
          classes="w-full px-sm md:px-0"
          :label="t('favLists.multiSelect.deleteItems')"
          :disabled="isLoading"
          full-width
          @click="onDelete()"
        ></ButtonBasic>
        <div
          class="flex items-center justify-center w-full gap-2 cursor-pointer text-primary-base mt-md"
          @click="dialogStore.closeDialog()"
        >
          <span class="anim__underline-link anim-link">
            {{ t('formFields.form.cancel') }}
          </span>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import Dialog from '@/components/dialog/components/dialogFrame/empty/dialogFrame.vue';
import ButtonBasic from '~~/src/components/components/core/basic-button/basic-button.vue';
import HeadlineDynamic from '@/components/components/core/headline-dynamic/headline-dynamic.vue';
import { useMultiSelectDelete } from './useMultiSelectDelete';
import { useDialogStore } from '~/stores/useDialogStore';
import { ErrorIdent } from '~/@types/errorIdents';

const { t } = useTrans();
const dialogStore = useDialogStore();

const { onDelete, isLoading, errors } = useMultiSelectDelete();
</script>
